<template>
  <main>
    <header-black />
    <heavy-car-descr />
    <heavy-car-list />
    <home-contact-us />
  </main>
</template>

<script>
export default {
  name: 'HeavyCarPage',
  components: {
    HeaderBlack: () => import('@/components/HeaderBlack'),
    HeavyCarDescr: () => import('@/components/HeavyCarDescr'),
    HeavyCarList: () => import('@/components/HeavyCarList'),
    HomeContactUs: () => import('@/components/HomeContactUs')
  }
}
</script>
